export var checkValidIds = function (formId, endUserId, hashId) {
    var isLetterAndDigits = function (value) { return /^[A-Za-z0-9]+$/.test(value); };
    var isDigits = function (value) { return /^\d+$/.test(value); };
    var invalidIds = [];
    if (!isLetterAndDigits(formId)) {
        invalidIds.push("Form ID: ".concat(formId));
    }
    if (!isDigits(endUserId)) {
        invalidIds.push("End User ID: ".concat(endUserId));
    }
    if (!isLetterAndDigits(hashId)) {
        invalidIds.push("Hash ID: ".concat(hashId));
    }
    return invalidIds;
};
