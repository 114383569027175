var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { object, string, number, mixed } from 'yup';
import { FormElementType } from 'zeals-protobuf/zeals/web/entities/form_pb';
//TODO: DEFINE CUSTOMFORMROW TYPE
export function createWebformSchema(formikFields) {
    return object().shape(__assign({}, formikFields.reduce(function (schema, field) {
        // TODO: REFACTOR
        if (field.required) {
            schema[field.formElementId] = string().required('必須です');
        }
        if (field.type === FormElementType.FORM_ELEMENT_TYPE_TEXT &&
            field.validation) {
            var textValidation = string().matches(new RegExp(field.validation.regexp), {
                message: field.errorMessage
                    ? "".concat(field.errorMessage)
                    : "".concat(field.name, "\u304C\u4E0D\u6B63\u3067\u3059"),
            });
            schema[field.formElementId] = field.required
                ? textValidation.required('必須です')
                : textValidation;
            return schema;
        }
        if (field.type === FormElementType.FORM_ELEMENT_TYPE_NUMERIC &&
            field.validation) {
            var validate = number().positive().integer();
            var validateMinMax = validate
                .min(field.validation.min, "".concat(field.errorMessage))
                .max(field.validation.max, "".concat(field.errorMessage));
            var numericValidation = field.validation.min === 0 && field.validation.max === 0
                ? validate
                : validateMinMax;
            schema[field.formElementId] = field.required
                ? numericValidation.required('必須です')
                : numericValidation;
            return schema;
        }
        if (field.type === FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER &&
            field.datePicker) {
            var disableBefore_1, disableAfter_1;
            if (field.datePicker.disableBefore) {
                disableBefore_1 = new Date();
                disableBefore_1.setDate(field.datePicker.disableBefore.day);
                disableBefore_1.setMonth(field.datePicker.disableBefore.month - 1);
                disableBefore_1.setFullYear(field.datePicker.disableBefore.year);
                disableBefore_1.setHours(0, 0, 0, 0);
            }
            if (field.datePicker.disableAfter) {
                disableAfter_1 = new Date();
                disableAfter_1.setDate(field.datePicker.disableAfter.day);
                disableAfter_1.setMonth(field.datePicker.disableAfter.month - 1);
                disableAfter_1.setFullYear(field.datePicker.disableAfter.year);
                disableAfter_1.setHours(0, 0, 0, 0);
            }
            var formatDate_1 = function (date) {
                return date.toISOString().split('T')[0].replace(/-/g, '/');
            };
            var errorMessage = function () {
                var validBefore, validAfter;
                if (disableBefore_1) {
                    validBefore = new Date(disableBefore_1);
                    validBefore.setDate(disableBefore_1.getDate() + 1);
                }
                if (disableAfter_1) {
                    validAfter = new Date(disableAfter_1);
                    validAfter.setDate(disableAfter_1.getDate() + 1);
                }
                if (disableBefore_1 && disableAfter_1) {
                    return "".concat(formatDate_1(validBefore), " ~ ").concat(formatDate_1(validAfter), "\u306E\u9593\u306E\u65E5\u4ED8\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044");
                }
                if (disableBefore_1 && !disableAfter_1) {
                    return "".concat(formatDate_1(validBefore), "\u4EE5\u964D\u306E\u65E5\u4ED8\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044");
                }
                if (!disableBefore_1 && disableAfter_1) {
                    return "".concat(formatDate_1(validAfter), "\u4EE5\u524D\u306E\u65E5\u4ED8\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044");
                }
            };
            var datePickerValidation = mixed().test('date', errorMessage, function (value) {
                var date;
                if (value) {
                    date = new Date(value);
                    date.setHours(0, 0, 0, 0);
                }
                if (date) {
                    if (disableAfter_1 && disableBefore_1) {
                        if (date >= disableBefore_1 && date <= disableAfter_1) {
                            return true;
                        }
                        return false;
                    }
                    if (disableAfter_1) {
                        if (date <= disableAfter_1) {
                            return true;
                        }
                        return false;
                    }
                    if (disableBefore_1) {
                        if (date >= disableBefore_1) {
                            return true;
                        }
                        return false;
                    }
                }
                return true;
            });
            schema[field.formElementId] = field.required
                ? datePickerValidation.required('必須です')
                : datePickerValidation;
            return schema;
        }
        return schema;
    }, {})));
}
