var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormElementType, CompletionStatus, } from 'zeals-protobuf/zeals/web/entities/form_pb';
import toDatePickerFormat from 'lib/helpers/toDatePickerFormat';
export var initialState = {
    hashId: '',
    endUserId: 0,
    form: {
        id: '',
        name: 'initial',
        formBuildId: '',
        pagesList: [],
        logoUri: '',
        styles: {
            themeColor: '#1739A5',
        },
        templateId: '',
        usage: 0,
        completionStatus: CompletionStatus.COMPLETION_STATUS_UNSPECIFIED,
        triggerFormAutomation: false,
    },
    endUserForm: {
        formId: '',
        endUserId: 0,
        rowsList: [],
    },
    calendarOptions: {},
    calendar: [],
    formikFields: [],
    endUserFields: [],
    cookies: {},
    isLoading: false,
};
export var ActionType;
(function (ActionType) {
    ActionType["SET_HASH_ID"] = "SET_HASH_ID";
    ActionType["SET_ENDUSER_ID"] = "SET_ENDUSER_ID";
    ActionType["SET_FORM"] = "SET_FORM";
    ActionType["SET_FORM_ROWS"] = "SET_FORM_ROWS";
    ActionType["SET_FORMIK_FIELDS"] = "SET_FORMIK_FIELDS";
    ActionType["CREATE_FORMIK_FIELDS"] = "CREATE_FORMIK_FIELDS";
    ActionType["SET_ENDUSER_FORM"] = "SET_ENDUSER_FORM";
    ActionType["SET_PREVIOUS_ENDUSER_ANSWERS"] = "SET_PREVIOUS_ENDUSER_ANSWERS";
    ActionType["SET_CALENDAR_OPTIONS"] = "SET_CALENDAR_OPTIONS";
    ActionType["SET_COOKIES"] = "SET_COOKIES";
    ActionType["SET_IS_LOADING"] = "SET_IS_LOADING";
})(ActionType || (ActionType = {}));
export default function reducer(state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.SET_HASH_ID:
            return __assign(__assign({}, state), { hashId: action.hashId });
        case ActionType.SET_ENDUSER_ID:
            return __assign(__assign({}, state), { endUserId: action.endUserId });
        case ActionType.SET_FORM:
            return __assign(__assign({}, state), { form: action.form });
        case ActionType.SET_FORMIK_FIELDS:
            return __assign(__assign({}, state), { formikFields: action.newSelectedFormValues });
        case ActionType.CREATE_FORMIK_FIELDS:
            var formikFields = action.form.pagesList
                .map(function (page) { return page.elementsList.map(function (el) { return el; }); })
                .flat()
                .map(function (row) {
                switch (row.type) {
                    case FormElementType.FORM_ELEMENT_TYPE_TEXT:
                        return {
                            formElementId: row.id,
                            name: row.name,
                            type: row.type,
                            required: row.required,
                            validation: row.text.validation ? row.text.validation : null,
                            errorMessage: row.errorMessage,
                        };
                    case FormElementType.FORM_ELEMENT_TYPE_NUMERIC:
                        return {
                            formElementId: row.id,
                            name: row.name,
                            type: row.type,
                            required: row.required,
                            validation: row.numeric.validation
                                ? row.numeric.validation
                                : null,
                            errorMessage: row.errorMessage,
                        };
                    case FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER:
                        var id = row.id, name = row.name, type = row.type, required = row.required, datePicker = row.datePicker;
                        return {
                            formElementId: id,
                            name: name,
                            type: type,
                            required: required,
                            datePicker: {
                                disableBefore: datePicker.disableBefore.year
                                    ? datePicker.disableBefore
                                    : null,
                                disableAfter: datePicker.disableAfter.year
                                    ? datePicker.disableAfter
                                    : null,
                                openingDaysList: [0],
                            },
                        };
                    default:
                        return {
                            formElementId: row.id,
                            name: row.name,
                            type: row.type,
                            required: row.required,
                            errorMessage: row.errorMessage,
                        };
                }
            });
            return __assign(__assign({}, state), { formikFields: formikFields });
        case ActionType.SET_ENDUSER_FORM:
            return __assign(__assign({}, state), { endUserForm: action.endUserForm });
        // IF PREVIOUSLY ANSWERED VALUE EXSISTS IN ENDUSERFORM, CONVERT TO FORMIK VALUE. WILL BE NEEDED FOR DEFAULT ININT VLAUE
        case ActionType.SET_PREVIOUS_ENDUSER_ANSWERS:
            var endUserFields = (_b = (_a = action.endUserForm) === null || _a === void 0 ? void 0 : _a.rowsList) === null || _b === void 0 ? void 0 : _b.filter(function (row) { return row.type !== FormElementType.FORM_ELEMENT_TYPE_CALENDAR; }).map(function (row) {
                if (row.text)
                    return { formElementId: row.formElementId, value: row.text.value };
                if (row.numeric)
                    return {
                        formElementId: row.formElementId,
                        value: row.numeric.value,
                    };
                if (row.selections)
                    return {
                        formElementId: row.formElementId,
                        value: row.selections.choicesList[0],
                    };
                if (row.date)
                    return {
                        formElementId: row.formElementId,
                        value: toDatePickerFormat(row.date),
                    };
                if (row.time)
                    return {
                        formElementId: row.formElementId,
                        value: "".concat(row.time.hours, ":").concat(row.time.minutes, ":").concat(row.time.seconds, ".").concat(row.time.nanos),
                    };
                if (row.datetime)
                    return {
                        formElementId: row.formElementId,
                        value: new Date(row.datetime.seconds).toISOString().slice(0, -1),
                    };
                return null;
            });
            return __assign(__assign({}, state), { endUserFields: endUserFields });
        case ActionType.SET_CALENDAR_OPTIONS:
            return __assign(__assign({}, state), { calendarOptions: __assign(__assign({}, state.calendarOptions), action.options) });
        case ActionType.SET_COOKIES:
            return __assign(__assign({}, state), { cookies: __assign(__assign({}, state.cookies), action.cookies) });
        case ActionType.SET_IS_LOADING:
            return __assign(__assign({}, state), { isLoading: action.isLoading });
        default:
            return state;
    }
}
