import { CreditCard, FormRow, ProtoDate, ProtoStringValue, ProtoTime, ProtoTimestamp, ProtoDoubleValue, FormSelectAnswer, } from '@grpc/formServiceClient';
import transformStringToProtoObjectKey from 'lib/helpers/transformStringToProtoObjectKey';
export var createCreditCardInstance = function (creditCard) {
    var creditCardInstance = new CreditCard();
    creditCardInstance.setPan(creditCard.pan);
    creditCardInstance.setCvv(creditCard.cvv);
    creditCardInstance.setExpireMonth(creditCard.expireMonth);
    creditCardInstance.setExpireYear(creditCard.expireYear);
    creditCardInstance.setName(creditCard.name);
    return creditCardInstance;
};
export var createDateInstance = function (date) {
    var dateInstance = new ProtoDate();
    dateInstance.setDay(date.day);
    dateInstance.setMonth(date.month);
    dateInstance.setYear(date.year);
    return dateInstance;
};
export var createDatetimeInstance = function (datetime) {
    var timestampInstance = new ProtoTimestamp();
    timestampInstance.setSeconds(datetime.seconds);
    timestampInstance.setNanos(datetime.nanos);
    return timestampInstance;
};
export var createTimeInstance = function (time) {
    var timeInstance = new ProtoTime();
    timeInstance.setHours(time.hours);
    timeInstance.setMinutes(time.minutes);
    timeInstance.setSeconds(time.seconds);
    timeInstance.setNanos(time.nanos);
    return timeInstance;
};
export var createTextInstance = function (text) {
    var textInstance = new ProtoStringValue();
    textInstance.setValue(text.value);
    return textInstance;
};
export var createNumberInstance = function (number) {
    var numberInstance = new ProtoDoubleValue();
    numberInstance.setValue(number.value);
    return numberInstance;
};
export var createSelectionsInstance = function (selections) {
    var selectionsInstance = new FormSelectAnswer();
    selectionsInstance.setChoicesList(selections.choicesList);
    return selectionsInstance;
};
export function convertFormRowFromObject(formRowData) {
    var newFormRow = new FormRow();
    var handleFormRowElements = function (key) {
        switch (key) {
            case 'creditCard':
                var creditCard = createCreditCardInstance(formRowData.creditCard);
                newFormRow.setCreditCard(creditCard);
                break;
            case 'date':
                var date = createDateInstance(formRowData.date);
                newFormRow.setDate(date);
                break;
            case 'time':
                var time = createTimeInstance(formRowData.time);
                newFormRow.setTime(time);
                break;
            case 'datetime':
                var datetime = createDatetimeInstance(formRowData.datetime);
                newFormRow.setDatetime(datetime);
                break;
            case 'text':
                var text = createTextInstance(formRowData[key]);
                newFormRow.setText(text);
                break;
            case 'numeric':
                var number = createNumberInstance(formRowData[key]);
                newFormRow.setNumeric(number);
                break;
            case 'selections':
                var selections = createSelectionsInstance(formRowData[key]);
                newFormRow.setSelections(selections);
                break;
            default:
                var preparedKey = transformStringToProtoObjectKey(key);
                newFormRow["set".concat(preparedKey)](formRowData[key]);
                break;
        }
    };
    Object.keys(formRowData).forEach(handleFormRowElements);
    return newFormRow;
}
export default convertFormRowFromObject;
