var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import styled, { ThemeProvider } from 'styled-components';
import { StoreContext } from '@context/webview/storeContext';
import { useRouter } from 'next/router';
import { createWebformSchema } from 'lib/validator/schema';
import featureFlags from 'lib/helpers/featureFlags';
import { QA_KEY } from 'lib/constants';
import { checkValidIds } from 'lib/helpers/checkValidIds';
import { handleInvalidIdInUrl } from 'lib/helpers/handleInvalidIdInUrl';
var Header = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  /* background-color: ", "; */\n"], ["\n  /* background-color: ", "; */\n"])), function (props) { return props.theme.main; });
var WebformContainer = function (_a) {
    var _b;
    var children = _a.children;
    var router = useRouter();
    var _c = router.query || {}, endUserId = _c.endUserId, formId = _c.formId, hashId = _c.hash_id, qaKey = _c.qa_key;
    var _d = useContext(StoreContext), _e = _d.state, logoUri = _e.form.logoUri, form = _e.form, formikFields = _e.formikFields, endUserFields = _e.endUserFields, endUserForm = _e.endUserForm, actions = _d.actions, fetchForm = _d.fetchForm, fetchEndUserForm = _d.fetchEndUserForm;
    var _f = useState([]), initialFields = _f[0], setInitialFields = _f[1];
    var isMaintenance = featureFlags.IS_MAINTENANCE && (!qaKey || qaKey !== QA_KEY);
    var hasIds = formId && endUserId && hashId;
    function fetchFormData() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchForm({
                            formId: String(formId),
                            endUserId: Number(endUserId),
                            hashId: String(hashId),
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, fetchEndUserForm({
                                formId: String(formId),
                                endUserId: Number(endUserId),
                                hashId: String(hashId),
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        if (isMaintenance)
            return function () { };
        var invalidIds = [];
        if (formId || endUserId || hashId) {
            invalidIds = checkValidIds(formId === null || formId === void 0 ? void 0 : formId.toString(), endUserId === null || endUserId === void 0 ? void 0 : endUserId.toString(), hashId === null || hashId === void 0 ? void 0 : hashId.toString());
        }
        if (!invalidIds.length && hasIds) {
            fetchFormData();
            actions.setHashId(String(hashId));
            actions.setEndUserId(Number(endUserId));
        }
        else {
            handleInvalidIdInUrl(invalidIds);
        }
    }, [endUserId, hashId, formId]);
    useEffect(function () {
        // MERGING FORMIKFIELDS AND ENDUSERFIELDS TO SET THE DEFAULT VALUE IF VALUE PRESENTS IN ENDUSERFORM(FROM CHATBOT)
        if (endUserFields.length && formikFields) {
            var newFormikFields = formikFields.map(function (field) { return (__assign(__assign({}, field), endUserFields.find(function (endUserField) { return endUserField.formElementId === field.formElementId; }))); });
            return setInitialFields(newFormikFields);
        }
        setInitialFields(formikFields);
    }, [endUserFields]);
    var theme = {
        main: (_b = form === null || form === void 0 ? void 0 : form.styles) === null || _b === void 0 ? void 0 : _b.themeColor,
    };
    //CREATE INIITIAL FORMIK STATE BASED ON THE INITIALFIELDS' IDS
    var initValues = initialFields === null || initialFields === void 0 ? void 0 : initialFields.reduce(function (obj, values) {
        obj[values.formElementId] = values.value || '';
        return obj;
    }, {});
    function handleFormikValues(values) {
        // CONVERT FORMIK STATE INTO THE NEW ROW FORMAT WITH BASIC VALUES AND SELECTED ANSWERS. VALUES WILL BE CONDITIONALLY CONVERTED TO THE CORRECT FORMAT PROPERTY WITH convertProperty() FUNCTION ABOVE
        var selectedFormValues = Object.keys(values).map(function (key) { return ({
            formElementId: key,
            value: values[key],
        }); });
        // MERGING TWO VALUES OF ARRAY INTO ONE ARRAY BY MATCHING IDS
        var newSelectedFormValues = formikFields.map(function (field) { return (__assign(__assign({}, field), selectedFormValues.find(function (selectedRow) { return selectedRow.formElementId === field.formElementId; }))); });
        actions.setFormikFields(newSelectedFormValues);
    }
    var formik = useFormik({
        enableReinitialize: true,
        validationSchema: createWebformSchema(formikFields),
        initialValues: __assign({}, initValues),
        validateOnMount: true,
        onSubmit: function (values) {
            handleFormikValues(values);
        },
    });
    var childrenWithProps = React.Children.map(children, function (child) {
        return React.cloneElement(child, { formik: formik });
    });
    return (<ThemeProvider theme={theme}>
      <div className="h-screen w-screen">
        <Header className="flex justify-center items-center h-1/6">
          {logoUri && <img className="max-h-12" src={logoUri} alt="Logo"/>}
        </Header>
        <div className="body h-5/6 font-sans">{childrenWithProps}</div>
      </div>
    </ThemeProvider>);
};
export default WebformContainer;
var templateObject_1;
