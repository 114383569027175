import { FormElementType, } from 'zeals-protobuf/zeals/web/entities/form_pb';
import { isDate } from 'lib/helpers/isDate';
import { ProtoTimestamp } from '@grpc/formServiceClient';
import dayjs from 'dayjs';
export default function convertToRows(formikFields) {
    var copiedFields = JSON.parse(JSON.stringify(formikFields));
    var filteredFields = copiedFields === null || copiedFields === void 0 ? void 0 : copiedFields.filter(function (field) { return field.value !== ''; });
    filteredFields.map(function (field) {
        switch (field.type) {
            case FormElementType.FORM_ELEMENT_TYPE_TEXT:
                return (field.text = {
                    value: field.value,
                });
            case FormElementType.FORM_ELEMENT_TYPE_NUMERIC:
                return (field.numeric = {
                    value: field.value,
                });
            case FormElementType.FORM_ELEMENT_TYPE_SELECT:
                return (field.selections = {
                    choicesList: [field.value],
                });
            case FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER:
                var year = +field.value.split('-')[0];
                var month = +field.value.split('-')[1];
                var day = +field.value.split('-')[2];
                return (field.date = {
                    year: year,
                    month: month,
                    day: day,
                });
            case FormElementType.FORM_ELEMENT_TYPE_TIME_PICKER:
                var hours = +field.value.split(':')[0];
                var minutes = +field.value.split(':')[1];
                return (field.time = {
                    hours: hours,
                    minutes: minutes,
                    seconds: 0,
                    nanos: 0,
                });
            case FormElementType.FORM_ELEMENT_TYPE_DATETIME_PICKER:
                var timestamp = new ProtoTimestamp();
                timestamp.fromDate(new Date(field.value));
                return (field.datetime = timestamp.toObject());
            //TODO: CONFIRM WITH BACKEND
            case FormElementType.FORM_ELEMENT_TYPE_CALENDAR:
                return (field.text = {
                    value: isDate(field.value)
                        ? dayjs(field.value).format('YYYY/MM/DD HH:mm')
                        : field.value,
                });
            default:
                break;
        }
    });
    return filteredFields.map(function (field) {
        delete field.name;
        delete field.required;
        delete field.validation;
        delete field.value;
        delete field.errorMessage;
        delete field.datePicker;
        return field;
    });
}
