import React from 'react';
import ErrorLogo from '@public/adicon_alert_filled.svg';
import PageTemplate from '@components/page-template';
var Error = function () {
    return (<PageTemplate imageSrc={ErrorLogo} header="エラーが発生しました。" description={<>
          ただいまサイトが大変混雑しております。
          <br />
          しばらく時間をおいてからのアクセスをお願いします。
        </>}/>);
};
export default Error;
