export default function actions(dispatch, _state) {
    return {
        setHashId: function (hashId) {
            dispatch({ type: 'SET_HASH_ID', hashId: hashId });
        },
        setEndUserId: function (endUserId) {
            dispatch({ type: 'SET_ENDUSER_ID', endUserId: endUserId });
        },
        setForm: function (form) {
            dispatch({ type: 'SET_FORM', form: form });
        },
        setFormikFields: function (newSelectedFormValues) {
            dispatch({ type: 'SET_FORMIK_FIELDS', newSelectedFormValues: newSelectedFormValues });
        },
        createFormikFields: function (form) {
            dispatch({ type: 'CREATE_FORMIK_FIELDS', form: form });
        },
        setEndUserForm: function (endUserForm) {
            dispatch({ type: 'SET_ENDUSER_FORM', endUserForm: endUserForm });
        },
        setPreviousEndUserAnswers: function (endUserForm) {
            dispatch({ type: 'SET_PREVIOUS_ENDUSER_ANSWERS', endUserForm: endUserForm });
        },
        setCalendarOptions: function (options) {
            dispatch({ type: 'SET_CALENDAR_OPTIONS', options: options });
        },
        setCookies: function (cookies) {
            dispatch({ type: 'SET_COOKIES', cookies: cookies });
        },
        setIsLoading: function (isLoading) {
            dispatch({ type: 'SET_IS_LOADING', isLoading: isLoading });
        },
    };
}
export function useActions(dispatch, state) {
    return actions(dispatch, state);
}
