var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { StringValue, DoubleValue, } from 'google-protobuf/google/protobuf/wrappers_pb';
import { FormServiceClient } from 'zeals-protobuf/zeals/web/services/FormServiceClientPb';
import { GetFormRequest, GetEndUserFormRequest, ReceiveEndUserFormRequest, } from 'zeals-protobuf/zeals/web/services/form_pb';
import { Form, FormElement, FormElementTextUsage, FormSelectRepresentationType, FormElementType, FormUsage, EndUserForm, FormRow, FormSelectAnswer, FormCalendarType, FormCalendarRemoteType, FormCalendarQuestionOption, } from 'zeals-protobuf/zeals/web/entities/form_pb';
import { Date as ProtoDate, Time as ProtoTime, } from 'zeals-protobuf/zeals/web/entities/common_pb';
import { CreditCard } from 'zeals-protobuf/zeals/web/entities/entities_pb';
import { getAuthEndUser } from '@api/auth';
import convertFormRowFromObject from 'lib/helpers/convertFormRowFromObject';
// exporting of types from protobuf
export { CreditCard, EndUserForm, Form, FormRow, FormElement, FormElementTextUsage, FormSelectAnswer, FormElementType, FormUsage, FormSelectRepresentationType, FormCalendarType, FormCalendarRemoteType, FormCalendarQuestionOption, GetFormRequest, ReceiveEndUserFormRequest, ProtoDate, ProtoTime, StringValue as ProtoStringValue, DoubleValue as ProtoDoubleValue, Timestamp as ProtoTimestamp, };
// Needs for SSR part of grpc-web working
if (!global.XMLHttpRequest) {
    global.XMLHttpRequest = require('xhr2');
}
/**
 * FormService gRPC client
 * @param jwtToken {String}
 */
var formServiceClient = new FormServiceClient('/api/grpc/form', null, null);
/**
 * Getting form to know what to show to a user
 * @param hashId {String}
 * @param formId {String}
 * @param endUserId {Number}
 */
export var getForm = function (_a) {
    var hashId = _a.hashId, formId = _a.formId, endUserId = _a.endUserId;
    return __awaiter(void 0, void 0, void 0, function () {
        var token, request;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getAuthEndUser({ hashId: hashId, endUserId: endUserId })];
                case 1:
                    token = (_b.sent()).token;
                    request = new GetFormRequest();
                    request.setId(formId);
                    return [2 /*return*/, new Promise(function (resolve, reject) {
                            var call = formServiceClient.getForm(request, { Authorization: token }, function (err, response) {
                                if (err) {
                                    // ToDo: Use our default error handler
                                    console.log(err);
                                    return reject(err);
                                }
                                // ToDo: Check the data and return normalized if needs
                                console.log(response.toObject());
                                return resolve(response.toObject());
                                // return resolve(makeFakeForm(formId, 1, '#fff'));
                            });
                            call.on('status', function (status) {
                                console.log(status);
                            });
                        })];
            }
        });
    });
};
/**
 * `Getting form data to prefill fields already filled by user
 * @param hashId
 * @param endUserId
 */
export var getEndUserForm = function (_a) {
    var hashId = _a.hashId, formId = _a.formId, endUserId = _a.endUserId;
    return __awaiter(void 0, void 0, void 0, function () {
        var token, request;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getAuthEndUser({ hashId: hashId, endUserId: endUserId })];
                case 1:
                    token = (_b.sent()).token;
                    request = new GetEndUserFormRequest();
                    request.setFormId(formId);
                    request.setEndUserId(endUserId);
                    return [2 /*return*/, new Promise(function (resolve, reject) {
                            var call = formServiceClient.getEndUserForm(request, { Authorization: token }, function (err, response) {
                                if (err) {
                                    // ToDo: Use our default error handler
                                    console.log(err);
                                    return reject(err);
                                }
                                // ToDo: Check the data and return normalized if needs
                                return resolve(response.toObject());
                            });
                            call.on('status', function (status) {
                                console.log(status);
                            });
                        })];
            }
        });
    });
};
/**
 * POSTing the filled form
 * @param hashId {String}
 * @param formId {String}
 * @param endUserId {Number}
 * @param data {EndUserForm.AsObject}
 */
export var receiveEndUserForm = function (_a) {
    var hashId = _a.hashId, formId = _a.formId, endUserId = _a.endUserId, data = _a.data;
    return __awaiter(void 0, void 0, void 0, function () {
        var token, request;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getAuthEndUser({ hashId: hashId, endUserId: endUserId })];
                case 1:
                    token = (_b.sent()).token;
                    request = new ReceiveEndUserFormRequest();
                    request.setFormId(formId);
                    request.setEndUserId(endUserId);
                    request.setRowsList(data.map(convertFormRowFromObject));
                    return [2 /*return*/, new Promise(function (resolve, reject) {
                            var call = formServiceClient.receiveEndUserForm(request, { Authorization: token }, function (err, response) {
                                if (err) {
                                    // ToDo: Use our default error handler
                                    console.log(err);
                                    return reject(err);
                                }
                                // ToDo: Check the data and return normalized if needs
                                console.log(response.toObject());
                                return resolve(response.toObject());
                            });
                            call.on('status', function (status) {
                                console.log(status);
                            });
                        })];
            }
        });
    });
};
