import { AppENV } from 'types/env';
import { hotjar } from 'react-hotjar';
import featureFlags from 'lib/helpers/featureFlags';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import '../tailwind.css';
import { StoreProvider } from '@context/webview/storeContext';
import { MUIThemeProvider, MUICssBaseline, } from '@zeals-co-ltd/washi-components';
import WebformContainer from '@components/WebformContainer/WebformContainer';
import ErrorBoundary from '@components/ErrorBoundary';
import { webformTheme } from '@zeals-co-ltd/washi-styles';
import { QA_KEY } from 'lib/constants';
export default function MyApp(_a) {
    var Component = _a.Component, pageProps = _a.pageProps;
    var router = useRouter();
    var qaKey = (router.query || {}).qa_key;
    useEffect(function () {
        if (process.env.appEnv === AppENV.PRODUCTION) {
            hotjar.initialize(2572495, 6);
        }
        if (featureFlags.IS_MAINTENANCE) {
            (!qaKey || qaKey !== QA_KEY) && router.push('/maintenance');
        }
    }, []);
    return (<StoreProvider>
      <MUICssBaseline>
        <MUIThemeProvider theme={webformTheme}>
          {/* TODO: USE next/head CORRECTLY
        <Head title="Zeals Integrated Webform" />; */}
          <ErrorBoundary>
            <WebformContainer>
              <Component {...pageProps}/>
            </WebformContainer>
          </ErrorBoundary>
        </MUIThemeProvider>
      </MUICssBaseline>
    </StoreProvider>);
}
