import dayjs from 'dayjs';
// SPECIFICALLY FOR THE DATE-PICKER @components/FormElements/date-picker-ui.tsx COMPONENT.
// ENDUSERFORM ROW WILL RETURN BELOW OBJECT
// EXAPMLE: {year: 2022, mounth: 1, day: 1} OR  {year: 2022, mounth: 12, day: 12}
export default (function (_a) {
    var year = _a.year, month = _a.month, day = _a.day;
    var dateObj = new Date();
    dateObj.setFullYear(year);
    dateObj.setMonth(month - 1);
    dateObj.setDate(day);
    return dayjs(dateObj).format('YYYY-MM-DD');
});
