import React from 'react';
import Image from 'next/image';
export var PageTemplate = function (_a) {
    var imageSrc = _a.imageSrc, header = _a.header, description = _a.description;
    return (<div className="h-5/6 flex items-center justify-center flex-col">
      <div style={{
            maxWidth: '64px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
        <Image src={imageSrc} alt="icon" width="64" height="64"/>
      </div>
      <p className="mt-6 font-bold text-center text-base">{header}</p>
      <div className="mt-3 text-center">{description}</div>
    </div>);
};
export default PageTemplate;
